import React from "react";
import { graphql } from 'gatsby';
// import Img from "gatsby-image";
import AOS from 'aos';

import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';
import ContactForm from '../layouts/ContactForm';

export default ({data}) =>{
  const page = data.wordpressPage;
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }
  return (
    <PrimaryLayout>
      <SEO 
        title={page.acf.seo_title}
        description={page.acf.seo_description}
        keywords={page.acf.seo_keywords}
      />
      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-5 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-6">
                <div className="header-text-container">
                  <div data-aos="fade-up" className="vertical-center" dangerouslySetInnerHTML={{__html: page.content}} />
                </div>
              </div>
              <div className="col-md-6">
                <img
                  data-src={page.featured_media && page.featured_media.source_url}
                  className="header-image lazyload"
                  alt="banenr"
                   data-aos="fade-up"
                />
                {/* <Img 
                  fixed={page.featured_media.localFile.childImageSharp.fixed}
                  alt={page.acf.seo_title}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid primary-color-bg ">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-12">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <h2 data-aos="fade-up">Contact Us.</h2>
                    <p data-aos="fade-up" className="pt-3 text-primary-color" dangerouslySetInnerHTML={{__html: page.acf.subheading_con}} />
                    <div className="row text-primary-color pt-3 main-container-padding-b-5">
                      {page.acf.contact_us && page.acf.contact_us.map((contact, index) => (
                        <div className="col-md-4 pr-4" key={index} data-aos="fade-up"  data-aos-delay={100 * index}>
                          <span style={{color: "#000000"}}>
                            <strong>{contact.contact_title}</strong>
                          </span>
                          <br />
                          <span dangerouslySetInnerHTML={{__html: contact.contact_info}} />
                        </div>
                      ))}
                    </div>

                    <hr />

                    <div className="row pt-3 pb-3">
                      {page.acf.our_locations && page.acf.our_locations.map((location, index) => (
                        <div className="col-md-2 pr-4" key={index} data-aos="fade-up"  data-aos-delay={100 * index}>
                          <span>
                            <strong>{location.location_con}.</strong>
                          </span>
                        </div>
                      ))}
                    </div>

                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div className="vertical-center">
                    <h2 data-aos="fade-up">Get in touch</h2>
                    <p data-aos="fade-up" className="pt-3">
                      {" "}
                      Please get in touch and we'll reply back within 24 hours.
                    </p>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="entry-content"></div>
                          </div>
                        </div>

                        <ContactForm />

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  )
}




export const query = graphql`
{
  wordpressPage(wordpress_id: {eq: 88}) {
    content
    featured_media {
      source_url
    }
    acf {
      subheading_con
      contact_us {
        contact_info
        contact_title
      }
      our_locations {
        location_con
      }
      seo_title
      seo_keywords
      seo_description
    }
  }
}
`